// IMPORTS
@import (reference) "../settings.less";

body[data-type="mainpage"] main.inner {
    padding-top: 40px;
}

// CATEGORY ICONS
:root {
    --categorySectionColumns: 8;
}

.icon-category-section {
    .grid();
    grid-template-columns: repeat(var(--categorySectionColumns), minmax(0, 1fr));
    gap: 40px 24px;
    position: relative;
    padding-bottom: 40px;
    align-items: stretch;

    .icon-category-dropdown[data-dropdown-id] {
        display: flex;
    }

    &:after {
        .hr-after();
        bottom: 0;
    }
}

a.icon-category-trigger {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    position: relative;
    cursor: pointer;
    text-align: center;
    color: @header-color;
    height: 100%;

    figure {
        width: 96px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;

        img {
            .img-contain();
        }
    }

    span {
        font-size: 13px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
        padding: 0 20px;
        position: relative;
        text-align: center;

        &:before {
            position: absolute;
            inset: -5px 12px;
            z-index: -1;
            content: '';
            background: transparent;
            border-radius: 8px;
            transition: background-color 0.1s ease-in-out;
        }
    }

    @media (min-width: 1280px) {
        &:hover {

            span {

                &:before {
                    background: @light-bg;
                }
            }
        }
    }

    &.dropdownActive {

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            bottom: -52px;
            background: @light-bg;
            width: 23px;
            aspect-ratio: 1;
            border-radius: 4px;
            opacity: 0;
            animation: showArrow 0.2s ease-in-out forwards;
        }
    }
}

@keyframes showArrow {
    100% {
        opacity: 1;
    }
}

@keyframes show {
    100% {
        opacity: 1;
        transform: none;
    }
}

.icon-category-dropdown {
    grid-column: 1 / -1;
    flex-direction: column;
    background: @light-bg;
    padding: 24px 32px;
    border-radius: 8px;
    gap: 24px;
    display: none;
    opacity: 0;
    transform: translateY(-20px);
    animation: show 0.2s ease-in-out forwards;

    a {
        color: @header-color;
        display: flex;
        gap: 12px;
        align-items: flex-start;

        &:hover {
            text-decoration: underline;
        }
    }

    .icon-subcategory-list {
        .grid();
        grid-template-columns: repeat( calc(var(--categorySectionColumns) - 2), minmax(0, 1fr));
        gap: 12px 46px;

        a:before {
            content: '';
            width: 4px;
            aspect-ratio: 1;
            border-radius: 50%;
            background: @header-color;
            flex-shrink: 0;
            position: relative;
            top: calc((var(--fontSize) + 1px) * var(--lineHeight) * 0.5);
            transform: translateY(-50%);
        }
    }

    .icon-subcategory-all {
        align-self: flex-start;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.3;
        flex-wrap: wrap;
        gap: 5px;

        span {
            text-transform: uppercase;
            position: relative;
            display: block;
            display: flex;
            align-items: center;
            gap: 12px;

            &:after {
                content: '';
                width: 4px;
                aspect-ratio: 1;
                border-radius: 50%;
                background: @header-color;
                position: relative;
            }
        }
    }
}

// MAINPAGE PRODUCTS
.slider-top {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .headline {
        margin-bottom: 0;
    }

    .buttons-wrapper {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    .slider-button1 {
        position: static;
    }
}


// MAINPAGE GREETING
.mainpage-greeting-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:before {
        .full-bg();
        background-color: @light-bg;
    }

    .mainpage-greeting {
        flex: 1;
        flex-direction: column;
        padding: 76px 0;
        margin-right: 155px;

        &:only-child {
            max-width: 70%;
            padding: 48px 0;
            text-align: center;
            margin-right: 0;
        }
    }

    figure {
        width: 944px;
        aspect-ratio: ~"944/550";

        img {
            display: block;
            height: 100%;
            aspect-ratio: ~"944/550";
            object-fit: cover;
        }
    }

    &:has(> *:nth-child(2)) {
        justify-content: space-between;
        padding-bottom: 0;

        figure {
            width: 704px;
            aspect-ratio: ~"704/550";
            align-self: flex-start;
            position: sticky;
            top: 24px;

            &:before,
            &:after {
                width: 11.65%;
                aspect-ratio: ~"82/80";
                content: '';
                position: absolute;
                background: @light-bg;
                left: -2px;
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }
        }
    }

    @media (max-width: 1919px) {

        &:has(> *:nth-child(2)) {

            .mainpage-greeting {
                margin-right: 56px;
            }

            figure {
                width: 60%;
                aspect-ratio: ~"944/550";
                top: 32px;

                img {
                    .img-crop();
                }

                &:before,
                &:after {
                    width: 8.69%;
                }
            }
        }
    }
}